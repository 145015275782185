import React, { useState, useEffect } from "react";
import axios from "axios";
import LoginPage from "./Loginpage";
import Popup from "./Popup";
import toast from "react-hot-toast";
import InquiryDetailsPopup from "../Pages/InquirydDetails";


const DetailsPage = ({ visible, closePopup, plotNumber }) => {
  const [isInquiry, setIsInquiry] = useState(false);
  const [isValidPlot, setIsValidPlot] = useState(true);
  const [popup, setPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [executiveEmail, setExecutiveEmail] = useState("");
  const [inquiryDetails, setInquiryDetails] = useState([]); // State to store inquiry details
  const [showInquiryDetailsPopup, setShowInquiryDetailsPopup] = useState(false); // State to control visibility of InquiryDetailsPopup
  const [plotDetails, setPlotDetails] = useState({
    plot_no: "",
    status: "",
    buyerName: "",
    buyerEmail: "",
    buyerMobileNo: "",
    Length: "",
    width: "",
    area: "",
  });

  useEffect(() => {
    // Reset plot details and set loading to true at the start of data fetch
    setPlotDetails({
      plot_no: "",
      status: "",
      buyerName: "",
      buyerEmail: "",
      buyerMobileNo: "",
      Length: "",
      width: "",
      area: "",
    });
    setIsValidPlot(false); // Optionally indicate that the plot validity is being checked
    setIsLoading(true); // Indicate loading

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchPlotDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_SUNCITY}/api/plot-details/${plotNumber}`, {
            cancelToken: source.token,
          }
        );

        const data = response.data;

        setPlotDetails({
          plot_no: data.plot_no || '',
          status: data.status || '',
          Length: data.Length || '',
          width: data.width || '',
          area: data.area || '',
          buyerName: data.buyerName || '',
          buyerEmail: data.buyerEmail || '',
          buyerMobileNo: data.buyerMobileNo || '',
        });
        setIsValidPlot(true);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching plot details:', error);
          toast.error("Failed to fetch plot details");
        }
      } finally {
        setIsLoading(false); // Reset loading state irrespective of the outcome
      }
    };

    fetchPlotDetails();

    return () => {
      source.cancel("Operation canceled due to new request or component unmounted.");
    };
  }, [plotNumber]);

  // Render logic
  if (isLoading) {
    return <div>Loading plot details...</div>; // Or any other loading indicator
  }

  const handleInquiry = () => {
    setIsInquiry(true);
    const user = localStorage.getItem("executive");
    const userEmail = localStorage.getItem("executiveEmail");
    if (user) {
      setPopup(true);
    }
    setIsInquiry(true);
    setExecutiveEmail(userEmail);
  };

  const handleCustomerDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SUNCITY}/api/listinquirybyplot?plotNumber=${plotNumber}`
      );
      setInquiryDetails(response.data); // Assuming this correctly fetches and sets data
      setShowInquiryDetailsPopup(true); // This should display the popup
    } catch (error) {
      console.error("Error fetching inquiry details:", error);
      toast.error("Failed to fetch inquiry details");
    }
  };
  

  const detailsContainerStyle = {
    display: visible ? "block" : "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "2px solid #333",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "350px",
    textAlign: "left",
    borderRadius: "8px",
    zIndex: "1000",
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px', // Adjust as needed for proper alignment
    right: '10px', // Adjust as needed for proper alignment
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  };

  return (
    <>
      {isInquiry ? (
        <LoginPage visible={true} closeLogin={() => setIsInquiry(false)} plotNumber={plotNumber} />
      ) : (
        <div style={detailsContainerStyle}>
        {plotDetails.status === "sold" ? (
            <h1 className="heading">Customer Details</h1>
          ) : (
            <h1 className="heading">Plot Details</h1>
          )}
          <div>
            <p>
              <strong>Plot No:</strong> {plotDetails.plot_no}
            </p>
            <p>
              <strong>Status:</strong> {plotDetails.status}
            </p>
            <p>
              <strong>Length:</strong> {plotDetails.Length} meters
            </p>
            <p>
              <strong>Width:</strong> {plotDetails.width} meters
            </p>
            <p>
              <strong>Area:</strong> {plotDetails.area} square meters
            </p>

            {/* Display buyer details if the plot is sold */}
            {plotDetails.status === "sold" && (
              <div>
                <p>
                  <strong>Buyer Name:</strong> {plotDetails.buyerName}
                </p>
                <p>
                  <strong>Buyer Email:</strong> {plotDetails.buyerEmail}
                </p>
                <p>
                  <strong>Buyer Mobile No:</strong> {plotDetails.buyerMobileNo}
                </p>
              </div>
            )}
          </div>

          {/* Render buttons based on plot status */}
          {(plotDetails.status === "available" || plotDetails.status === "reserved") && (
            <button
              type="submit"
              className="buttonStyle submit-button"
              onClick={handleInquiry}
              disabled={!isValidPlot}
            >
              Inquiry
            </button>
          )}

          {plotDetails.status === "reserved" && (
            <button
              type="button"
              className="buttonStyle submit-button"
              onClick={handleCustomerDetails}
              disabled={!isValidPlot}
            >
              Customer Details
            </button>
          )}
          {showInquiryDetailsPopup && (
        <InquiryDetailsPopup
          visible={showInquiryDetailsPopup}
          closePopup={() => setShowInquiryDetailsPopup(false)}
          inquiryDetails={inquiryDetails} // Pass fetched inquiry details as props
          
        />
      )}

      <button onClick={closePopup} style={closeButtonStyle}>
            ✖
          </button>
        </div>
      )}
      {popup && <Popup visible={true} closeInquiry={() => setPopup(false)} plotNumber={plotNumber} executiveEmail={executiveEmail} />}
    </>
  );
};

export default DetailsPage;